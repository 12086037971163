import Text from "../../../Atomic/Text"
import Spacing from "../../../Atomic/Spacing"

import React from "react"

export default function Paragraph({ children }) {
  return (
    <Spacing p={"sm"}>
      <Text as={`p`} align={"left"}>
        {children}
      </Text>
    </Spacing>
  )
}
