// import Flex from "../../../Atomic/Flex"
import Box from "../../../Atomic/Box"

import React from "react"

import prism from "prismjs"
import "prismjs/components/prism-jsx"
import "prismjs/components/prism-graphql"

export default function Code({ code }) {
  const options = {
    language: code.slice(0, code.indexOf("\n")),
    code: code.slice(code.indexOf("\n") + 1),
  }
  const tokens = prism.tokenize(options.code, prism.languages[options.language])
  const components = {
    keyword: (keyword, idx) => (
      <Box
        key={`${keyword}${idx}`}
        as={`span`}
        sx={{ color: "quinaryScale.1", textShadow: "0 0 2px #ffff66" }}
      >
        {keyword}
      </Box>
    ),
    operator: (operator, idx) => (
      <Box
        key={`${operator}${idx}`}
        as={`span`}
        sx={{ color: "quinaryScale.3" }}
      >
        {operator}
      </Box>
    ),
    punctuation: (token, idx) => (
      <Box key={`${token}${idx}`} as={`span`} sx={{ color: "quinaryScale.4" }}>
        {token}
      </Box>
    ),
    constant: (constant, idx) => (
      <Box
        key={`${constant}${idx}`}
        as={`span`}
        sx={{ color: "quinaryScale.3" }}
      >
        {constant}
      </Box>
    ),
    "template-string": (content, idx) => (
      <Box
        key={`${content}${idx}`}
        as={`span`}
        sx={{ color: "quinaryScale.3" }}
      >
        {content.map((item, index) =>
          components[item.type](item.content, index)
        )}
      </Box>
    ),
    parameter: (parameter, idx) => {
      return (
        <Box
          key={`${parameter}${idx}`}
          as={`span`}
          sx={{ color: "quinaryScale.3" }}
        >
          {parameter.map((item, index) => {
            if (typeof item === "object") {
              return components[item.type](
                item.content,
                Math.pow(idx, index + 1)
              )
            }
            return components.string(item, idx)
          })}
        </Box>
      )
    },
    string: (string, idx) => (
      <Box key={`${string}${idx}`} as={`span`} sx={{ color: "quinaryScale.5" }}>
        {string}
      </Box>
    ),
    function: (name, idx) => {
      return (
        <Box
          key={`${name}${idx}`}
          as={`span`}
          sx={{ color: "#ffff66", textShadow: "0 0 5px #ffff66" }}
        >
          {name}
        </Box>
      )
    },
    "template-punctuation": (token, idx) => (
      <Box key={`${token}${idx}`} as={`span`} sx={{ color: "quinaryScale.3" }}>
        {token}
      </Box>
    ),
    variable: (name, idx) => {
      return (
        <Box key={idx} as={`span`} sx={{ color: "quinaryScale.4" }}>
          {name}
        </Box>
      )
    },
    "attr-name": (name, idx) => {
      return (
        <Box key={idx} as={`span`} sx={{ color: "quinaryScale.2" }}>
          {name}
        </Box>
      )
    },
  }
  return (
    <Box
      as={`span`}
      sx={{
        backgroundColor: "#000",
        borderRadius: "md",
        padding: "sm",
        width: "fit-content",
        maxWidth: "100%",
        textAlign: "left",
        color: "quinaryScale.5",
        overflow: "scroll",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        maxHeight: "300px",
        "::-webkit-scrollbar": {
          width: 0,
        },
        display: "block",
      }}
    >
      <Box as={`pre`}>
        {tokens.map((token, idx) => {
          if (token?.type in components) {
            return components[token?.type](token.content, idx)
          } else if (typeof token === "string") {
            return components.string(token, idx)
          } else return null
        })}
      </Box>
    </Box>
  )
}
