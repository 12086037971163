import React from "react"
import PageStructure from "../components/PageStructure"
import Flex from "../components/Atomic/Flex"
import { graphql } from "gatsby"

import { BLOCKS, MARKS } from "@contentful/rich-text-types"
// import { renderRichText } from "gatsby-source-contentful/rich-text"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import {
  H1,
  H2,
  H3,
  H4,
  Paragraph,
  Code,
  Bold,
} from "../components/Homepage/MarkdownTemplates"

const options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (_, children) => {
      return <H1>{children}</H1>
    },
    [BLOCKS.HEADING_2]: (_, children) => {
      return <H2>{children}</H2>
    },
    [BLOCKS.HEADING_3]: (_, children) => {
      return <H3>{children}</H3>
    },
    [BLOCKS.HEADING_4]: (_, children) => {
      return <H4>{children}</H4>
    },
    [BLOCKS.PARAGRAPH]: (_, children) => {
      return <Paragraph>{children}</Paragraph>
    },
  },
  renderMark: {
    [MARKS.CODE]: code => <Code code={code} />,
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
}

export default function BlogPost({ data, location }) {
  const post = data.contentfulPost
  const markdown = JSON.parse(post.content.raw)
  // console.log("markdown", markdown)
  return (
    <PageStructure
      pageTitle={post.title}
      metaContent={`Blog post describing an event or day`}
      location={location}
    >
      <Flex as={`section`} sx={{ flexDirection: "column", width: "100%" }}>
        {/* <Box dangerouslySetInnerHTML={{ __html: post.html }} /> */}
        {markdown && documentToReactComponents(markdown, options)}
      </Flex>
    </PageStructure>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      publishDate
      slug
      title
      content {
        raw
      }
    }
  }
`
